import { stripHtml } from "@/utils/basicHelpers.js";
const twitterDomain = (process.env.VUE_APP_WEB_URL || "")
  .replace(/^https?:\/\//, "")
  .replace(/:[0-9]+$/, "");

const imageMimeTypes = {
  bmp: "image/bmp",
  gif: "image/gif",
  ico: "image/vnd.microsoft.ico",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  tif: "image/tiff",
  tiff: "image/tiff",
  webp: "image/webp",
  svg: "image/svg+xml"
};

const videoMimeTypes = {
  mp4: "video/mp4",
  m3u8: "application/x-mpegURL",
  ts: "video/MP2T",
  "3gp": "video/3gpp"
};

export const openGraphMetaTags = options => {
  const imageMimeType =
    imageMimeTypes[(options.metaImageUrl || "").match(/\.([^.]+)$/)?.[1]];

  const videoMimeType =
    videoMimeTypes[(options.metaVideoUrl || "").match(/\.([^.]+)$/)?.[1]];

  const tags = [];
  tags.push({
    id: "prerender-status-code",
    name: "prerender-status-code",
    content: ""
  });

  if (options.metaDescription) {
    tags.push({
      id: "desc",
      name: "description",
      content: stripHtml(options.metaDescription)
    });
  }
  if (options.keywords) {
    tags.push({
      id: "keywords",
      name: "keywords",
      content: options.keywords
    });
  }
  if (options.metaDescription || options.metaTitle) {
    tags.push(
      {
        id: "og-url",
        property: "og:url",
        content: process.env.VUE_APP_WEB_URL + options.fullPath
      },
      {
        id: "og-type",
        property: "og:type",
        content: "website"
      },
      {
        id: "og-title",
        property: "og:title",
        content: stripHtml(options.metaTitle)
      },
      {
        id: "og-description",
        property: "og:description",
        content: stripHtml(options.metaDescription)
      }
    );
  }
  if (options.metaImageUrl && imageMimeType) {
    tags.push(
      {
        id: "og-image",
        property: "og:image",
        content: options.metaImageUrl
      },
      {
        id: "og-image-type",
        property: "og:image:type",
        content: imageMimeType
      }
    );
  }

  if (options.metaVideoUrl && videoMimeType) {
    tags.push(
      {
        id: "og-video",
        property: "og:video",
        content: options.metaVideoUrl
      },
      {
        id: "og-video-type",
        property: "og:video:type",
        content: videoMimeType
      }
    );
  }

  if (options.metaDescription && options.metaTitle) {
    tags.push(
      {
        id: "twitter-card",
        name: "twitter:card",
        content: "summary_large_image"
      },
      {
        id: "twitter-domain",
        property: "twitter:domain",
        content: twitterDomain
      },
      {
        id: "twitter-url",
        property: "twitter:url",
        content: process.env.VUE_APP_WEB_URL + options.fullPath
      },
      {
        id: "twitter-title",
        name: "twitter:title",
        content: stripHtml(options.metaTitle)
      },
      {
        id: "twitter-description",
        name: "twitter:description",
        content: stripHtml(options.metaDescription)
      }
    );
  }
  if (options.metaImageUrl && imageMimeType) {
    tags.push(
      {
        id: "twitter-image",
        name: "twitter:image",
        content: options.metaImageUrl
      },
      {
        id: "twitter-image-type",
        name: "twitter:image:type",
        content: imageMimeType
      }
    );
  }
  return tags;
};

export const canonicalLink = options => {
  return [
    {
      id: "canonical",
      rel: "canonical",
      href: `${process.env.VUE_APP_WEB_URL}${options.fullPath}`
    }
  ];
};

export const seoMetadata = options => {
  return {
    meta: openGraphMetaTags(options),
    link: canonicalLink(options)
  };
};
