<template>
  <div
    class="bg-yellow-300 pb-12 pt-14 md:pt-20 text-black flex flex-col justify-center items-center"
  >
    <div class="flex flex-col items-center w-9/12 max-landing-content">
      <h1 class="text-center">
        {{ title }}
      </h1>
      <div class="items flex flex-row mx-auto">
        <div class="item">
          <img src="@/assets/img/icons/time.svg" alt="time" />
          <span>
            {{ $t("message.campaign.give-time") }}
          </span>
        </div>
      </div>
    </div>
    <img
      src="/img/campaigns/faces/desktop-banner.png"
      alt="profiles"
      class="hidden md:flex mx-auto w-full"
    />
    <img
      src="/img/campaigns/faces/mobile-banner.png"
      alt="profiles"
      class="md:hidden mx-auto w-full"
    />
    <div class="flex flex-col items-center w-9/12 max-landing-content">
      <div class="flex flex-col justify-center items-center mt-8">
        <h2 v-if="bodyTitle !== ''" class="text-center">
          {{ bodyTitle }}
        </h2>
        <p v-html="body" class="text-center mt-4"></p>
        <router-link
          v-if="!isLogged"
          class="capitalize btn btn-default mt-6 max-btn w-full md:w-52"
          :to="{ name: 'SignUpEmailForm' }"
        >
          {{ $t("message.get-started") }}
        </router-link>
        <router-link
          v-else
          class="capitalize btn btn-default mt-6 max-btn w-full md:w-52"
          :to="{ name: 'HomeIn' }"
        >
          {{ $t("message.campaign.discover-causes") }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Block2",
  props: {
    title: {
      type: String,
      required: true
    },
    bodyTitle: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("auth", ["isLogged"])
  }
};
</script>
<style lang="postcss" scoped>
h1 {
  max-width: 600px;
  @apply mx-auto text-center;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 32px;
  }
}
.items {
  width: fit-content;
  @apply mt-6;
  .item {
    @apply flex flex-row justify-center;
    img {
      @apply mr-2;
    }
  }
}
.down {
  @apply mt-auto mb-0;
}
.top {
  @apply mb-auto mt-0;
}
.center {
  @apply mb-0 mt-0;
}
#faces {
  @apply mt-4;
  min-height: 250px;
  img {
    @apply mx-2 mb-auto w-20 h-20;
  }
  img:nth-of-type(2) {
    @apply down;
  }
  img:nth-of-type(3) {
    @apply center;
  }
  img:nth-of-type(4) {
    @apply down;
  }
  img:nth-of-type(5) {
    @apply center;
  }
  img:nth-of-type(6) {
    @apply down;
  }
  img:nth-of-type(7) {
    @apply top;
  }
  img:nth-of-type(8) {
    @apply center;
  }
  @media (max-width: 768px) {
    img {
      width: 58px;
      height: 58px;
      margin-left: -2px;
      margin-right: -2px;
    }
  }
  @media (max-width: 440px) {
    img {
      width: 58px;
      height: 58px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
</style>
