<template>
  <div
    class="bg-gray-50 px-4 pb-12 pt-20 text-black text-center flex flex-col justify-center items-center"
  >
    <template v-if="!isLogged">
      <h1 class="mx-auto mb-4">
        {{ title }}
      </h1>
      <p>
        {{ subtitle }}
      </p>
      <div class="form flex justify-center flex-col text-center items-center">
        <EmailForm :showTitle="false"></EmailForm>
      </div>
    </template>
    <template v-else>
      <h1 class="mx-auto mb-4">
        {{ titleLogged || $t("message.campaign.ready") }}
      </h1>
      <p>
        {{ subtitleLogged || $t("message.campaign.browse-all-causes") }}
      </p>
      <div id="images" class="flex flex-row mx-auto justify-center">
        <img
          v-for="(image, i) in teamImages"
          :key="i"
          :src="image.url"
          :alt="image.altText"
        />
      </div>
      <router-link
        class="capitalize btn btn-default btn-lg mt-16"
        :to="{ name: 'HomeIn' }"
      >
        {{ $t("message.campaign.discover-causes") }}
      </router-link>
    </template>
  </div>
</template>
<script>
import EmailForm from "../../views/signUp/EmailForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "Block5",
  components: {
    EmailForm
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    titleLogged: {
      type: String,
      required: true
    },
    subtitleLogged: {
      type: String,
      required: true
    },
    teamImages: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters("auth", ["isLogged"])
  }
};
</script>
<style lang="postcss" scoped>
h2 {
  max-width: 632px;
}
p {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.03em;
}
.form {
  max-width: 400px;
  margin-top: 30px;
}
.down {
  @apply mt-auto mb-0;
}
.top {
  @apply mb-auto mt-0;
}
#images {
  height: 300px;
  @apply mt-6;
  img {
    @apply mx-8 rounded-md;
  }
  @media (max-width: 980px) {
    height: 200px;
    img {
      height: 120px;
      width: 120px;
      @apply mx-8;
    }
  }
  @media (max-width: 768px) {
    height: 130px;
    img {
      @apply mx-1;
      width: 98px;
      height: 98px;
    }
  }
  img {
    @apply top;
  }
  img:nth-of-type(2) {
    @apply down;
  }
  img:nth-of-type(3) {
    @apply top;
  }
  img:nth-of-type(4) {
    @apply down;
  }
}
</style>
