import { render, staticRenderFns } from "./Block3.vue?vue&type=template&id=4768c6b3&scoped=true&"
import script from "./Block3.vue?vue&type=script&lang=js&"
export * from "./Block3.vue?vue&type=script&lang=js&"
import style0 from "./Block3.vue?vue&type=style&index=0&id=4768c6b3&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4768c6b3",
  null
  
)

export default component.exports