<template>
  <div class="relative">
    <img class="md:hidden w-full" :src="imageMobile" :alt="imgAltText" />
    <img class="hidden md:block w-full" :src="imageDesktop" :alt="imgAltText" />
    <div
      class="title-box w-full flex justify-center items-center p-4 md:absolute md:bottom-10"
      :style="`background-color: ${titleBg}`"
    >
      <h1 class="text-white text-center">
        {{ title }}
      </h1>
    </div>
  </div>
</template>
<script>
export default {
  name: "CampaignBanner",
  props: {
    title: {
      type: String,
      required: true
    },
    titleBg: {
      type: String,
      required: true
    },
    imageDesktop: {
      type: String,
      required: true
    },
    imageMobile: {
      type: String,
      required: true
    },
    imgAltText: {
      type: String,
      required: true
    }
  }
};
</script>
<style lang="postcss" scoped>
.title-box {
  max-width: 982px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 24px;
  @media (max-width: 768px) {
    width: 100%;
  }
}
h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 56px;

  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }
}
</style>
