<template>
  <div
    class="bg-purple-200 pb-12 pt-14 md:pt-20 text-black flex flex-col justify-center items-center"
  >
    <div class="flex flex-col w-9/12 max-landing-content">
      <span
        class="mobile-step mb-2 text-black text-opacity-60 mr-4 block md:hidden"
      >
        {{ titleSpan || $t("message.campaign.step-2") }}:
      </span>
      <h1 class="mb-4">
        <span class="text-black text-opacity-60 mr-4 hidden md:block">
          {{ titleSpan || $t(`message.campaign.step-2`) }}
        </span>
        {{ title || $t(`message.campaign.complete-a-task`) }}
      </h1>
      <p v-html="body"></p>
      <div class="images flex flex-row mx-auto justify-center mt-12">
        <img
          v-for="(image, i) in taskImages"
          :key="i"
          :src="image.url"
          :alt="image.altText"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Block4",
  props: {
    title: {
      type: String,
      required: true
    },
    titleSpan: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    taskImages: {
      type: Array,
      required: true
    }
  }
};
</script>
<style lang="postcss" scoped>
.mobile-step {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}
h2 {
  max-width: 632px;
}
.down {
  @apply mt-auto mb-0;
}
.top {
  @apply mb-auto mt-0;
}
.images {
  height: 185px;
  @apply mt-12;
  img {
    @apply mx-4 rounded-md;
  }
  @media (max-width: 980px) {
    height: 162px;
    img {
      height: 120px;
      width: 120px;
      @apply mx-4;
    }
  }
  @media (max-width: 870px) {
    height: 135px;
    img {
      @apply mx-1;
      width: 98px;
      height: 98px;
    }
  }
  @media (max-width: 620px) {
    height: 115px;
    img {
      @apply mx-1;
      width: 78px;
      height: 78px;
    }
  }
  @media (max-width: 450px) {
    height: 80px;
    img {
      @apply mx-1;
      width: 60px;
      height: 60px;
    }
  }
  img {
    @apply down;
  }
  img:nth-of-type(2) {
    @apply top;
  }
  img:nth-of-type(3) {
    @apply down;
  }
  img:nth-of-type(4) {
    @apply top;
  }
  img:nth-of-type(5) {
    @apply down;
  }
  img:nth-of-type(6) {
    @apply top;
  }
}
</style>
