import gql from "graphql-tag";

export const KENTICO_GET_CAMPAIGN = gql`
  query getCampaign($path: String!) {
    campaign_All(where: { block1Path: { eq: $path } }) {
      items {
        block1MainImage {
          image {
            url
          }
          imageAltText
          imageMobile {
            url
          }
        }
        block1Path
        block1Title
        block2Title
        block2BodyContent {
          html
        }
        block2BodyTitle
        block3TitleStepSpan
        block3Title
        block3Body {
          html
        }
        block3Image {
          url
        }
        block3ImageAltText
        block4TitleStepSpan
        block4Title
        block4Body {
          html
        }
        block4TaskImages {
          items {
            altText
            image {
              url
            }
          }
        }
        block5Subtitle
        block5Title
        block5SubtitleLogged
        block5TitleLogged
        block5TeamImages {
          items {
            image {
              url
            }
            altText
          }
        }
      }
    }
  }
`;

export const formatKenticoCampaign = kenticoCampaign => {
  const campaign = kenticoCampaign.campaign_All.items[0];

  const block1 = {
    title: campaign.block1Title,
    mainImage: {
      url: campaign.block1MainImage.image.url,
      mobileUrl: campaign.block1MainImage.imageMobile.url,
      altText: campaign.block1MainImage.imageAltText
    }
  };

  const block2 = {
    title: campaign.block2Title,
    bodyTitle: campaign.block2BodyTitle,
    body: campaign.block2BodyContent.html
  };

  const block3 = {
    title: campaign.block3Title,
    titleSpan: campaign.block3TitleStepSpan,
    body: campaign.block3Body.html,
    image: {
      url: campaign.block3Image.url,
      altText: campaign.block3ImageAltText
    }
  };

  const block4 = {
    title: campaign.block4Title,
    titleSpan: campaign.block4TitleStepSpan,
    body: campaign.block4Body.html,
    taskImages: campaign.block4TaskImages.items.map(item => {
      return {
        altText: item.altText,
        url: item.image.url
      };
    })
  };

  const block5 = {
    title: campaign.block5Title,
    subtitle: campaign.block5Subtitle,
    titleLogged: campaign.block5TitleLogged,
    subtitleLogged: campaign.block5SubtitleLogged,
    teamImages: campaign.block5TeamImages.items.map(item => {
      return {
        altText: item.altText,
        url: item.image.url
      };
    })
  };

  return {
    block1,
    block2,
    block3,
    block4,
    block5
  };
};
