<template>
  <div id="landing-page" class="w-full">
    <template v-if="campaign">
      <div
        class="head flex flex-row px-6 py-1 h-20 justify-between items-center bg-white"
      >
        <Logo
          tabindex="0"
          color="pink"
          class="logo block cursor-pointer"
          @click="goHome()"
        />
        <div>
          <router-link
            v-if="!isLogged"
            :to="{ name: 'SignUpEmailForm' }"
            class="btn btn-default btn-thin px-2 w-36 md:w-48"
          >
            {{ $t("message.campaign.sign-up-button") }}
          </router-link>
          <router-link v-else :to="{ name: 'MyProfilePage' }">
            <Avatar
              :displayName="currentUser.attributes.displayName"
              :profileImageUrl="currentUser.attributes.profileImageUrl"
            />
          </router-link>
        </div>
      </div>
      <Banner
        titleBg="#f93a5b"
        :title="campaign.block1.title"
        :imageDesktop="campaign.block1.mainImage.url"
        :imageMobile="campaign.block1.mainImage.mobileUrl"
        :imgAltText="campaign.block1.mainImage.altText"
      ></Banner>
      <Block2
        :title="campaign.block2.title"
        :bodyTitle="campaign.block2.bodyTitle"
        :body="campaign.block2.body"
      ></Block2>
      <Block3
        :titleSpan="campaign.block3.titleSpan"
        :title="campaign.block3.title"
        :body="campaign.block3.body"
        :image="campaign.block3.image"
      ></Block3>
      <Block4
        :titleSpan="campaign.block4.titleSpan"
        :title="campaign.block4.title"
        :body="campaign.block4.body"
        :taskImages="campaign.block4.taskImages"
      ></Block4>
      <Block5
        :title="campaign.block5.title"
        :subtitle="campaign.block5.subtitle"
        :titleLogged="campaign.block5.titleLogged"
        :subtitleLogged="campaign.block5.subtitleLogged"
        :teamImages="campaign.block5.teamImages"
      ></Block5>
    </template>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
import Block4 from "@/components/campaign/Block4.vue";
import Block2 from "@/components/campaign/Block2.vue";
import Block3 from "@/components/campaign/Block3.vue";
import Block5 from "@/components/campaign/Block5.vue";
import Banner from "@/components/campaign/Banner.vue";
import Avatar from "@/components/shared/Avatar.vue";
import { seoMetadata } from "@/utils/seoMetadata";
import { mapGetters } from "vuex";
import {
  KENTICO_GET_CAMPAIGN,
  formatKenticoCampaign
} from "@/kentico-api/campaigns.js";

export default {
  name: "Campaign",
  components: {
    Logo,
    Block4,
    Block2,
    Block3,
    Block5,
    Banner,
    Avatar
  },
  data() {
    return {
      campaign: null,
      path: this.$route.path.replace("/", "")
    };
  },
  head: function() {
    return seoMetadata({
      fullPath: this.$route.fullPath,
      metaTitle: this.title,
      metaImageUrl: this.image
    });
  },
  computed: {
    ...mapGetters("auth", ["isLogged", "currentUser"])
  },
  mounted() {
    this.loadCampaign(this.$route.fullPath);
  },
  methods: {
    goHome() {
      this.$router.push({ name: "HomeIn" }).catch(() => {});
    },
    loadCampaign() {
      this.$apollo
        .query({
          client: "kenticoClient",
          query: KENTICO_GET_CAMPAIGN,
          variables: {
            path: this.path
          },
          fetchPolicy: "no-cache"
        })
        .then(response => {
          if (response.data.campaign_All.items.length > 0) {
            this.campaign = formatKenticoCampaign(response.data);
          } else {
            this.$router.replace("/404");
          }
        });
    }
  }
};
</script>
<style lang="postcss">
#landing-page {
  .max-landing-content {
    max-width: 730px;
  }
}
</style>
