<template>
  <div
    class="bg-green-300 pb-12 pt-14 md:pt-20 text-black flex flex-col justify-center items-center"
  >
    <div class="flex flex-col items-center w-9/12 max-landing-content">
      <img
        :src="image.url"
        :alt="image.altText"
        class="w-40 md:w-4/5 main-image mx-auto rounded-md"
      />
      <div class="flex flex-col mt-16">
        <span
          class="mobile-step mb-2 text-black text-opacity-60 mr-4 block md:hidden"
        >
          {{ titleSpan || $t("message.campaign.step-1") }}:
        </span>
        <h1 class="mb-4">
          <span class="text-black text-opacity-60 mr-4 hidden md:block">
            {{ titleSpan || $t("message.campaign.step-1") }}
          </span>
          {{ title || $t("message.campaign.pick-a-team") }}
        </h1>
        <p v-html="body"></p>
        <router-link
          v-if="!isLogged"
          class="capitalize btn btn-default mt-6  max-btn w-full md:w-52 mx-auto"
          :to="{ name: 'SignUpEmailForm' }"
        >
          {{ $t("message.get-started") }}
        </router-link>
        <router-link
          v-else
          class="capitalize btn btn-default mt-6  max-btn w-full md:w-52 mx-auto"
          :to="{ name: 'HomeIn' }"
        >
          {{ $t("message.campaign.discover-causes") }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Block3",
  props: {
    title: {
      type: String,
      required: true
    },
    titleSpan: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("auth", ["isLogged"])
  }
};
</script>
<style lang="postcss" scoped>
.main-image {
  max-width: 470px;
}
.mobile-step {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}
h2 {
  max-width: 632px;
}
.down {
  @apply mt-auto mb-0;
}
.top {
  @apply mb-auto mt-0;
}
#images {
  height: 300px;
  @apply mt-6;
  img {
    @apply mx-8 rounded-md;
  }
  @media (max-width: 980px) {
    height: 200px;
    img {
      height: 120px;
      width: 120px;
      @apply mx-8;
    }
  }
  @media (max-width: 768px) {
    height: 130px;
    img {
      @apply mx-1;
      width: 98px;
      height: 98px;
    }
  }
  img {
    @apply top;
  }
  img:nth-of-type(2) {
    @apply down;
  }
  img:nth-of-type(3) {
    @apply top;
  }
  img:nth-of-type(4) {
    @apply down;
  }
}
</style>
